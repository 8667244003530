var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"stretch","atid":"targeted_config_view"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"atid":"title_text","dark":""}},[_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$lang("targetedConfig.title"))+" ")]),_c('test-info'),_c('v-spacer'),(_vm.hasCreatePermission)?_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.getters['existsDefaultConfig']},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._b({staticClass:"mx-4",attrs:{"color":"primary","atid":"add_button","disabled":!_vm.$store.getters['existsDefaultConfig'],"to":{ name: 'targeted_new' },"small":"","fab":"","link":""}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("add")])],1)],1)]}}],null,false,121438072)},[_c('span',[_vm._v(_vm._s(_vm.$lang("abTestConfig.addDefaultConfig")))])]):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-chip-group',{attrs:{"multiple":""},model:{value:(_vm.checkedConfigStatuses),callback:function ($$v) {_vm.checkedConfigStatuses=$$v},expression:"checkedConfigStatuses"}},_vm._l((_vm.TARGETED_STATUSES),function(status){return _c('div',{key:status,staticClass:"px-1 py-0"},[_c('v-chip',{attrs:{"value":status,"active-class":"primary"}},[_vm._v(" "+_vm._s(_vm.$lang(("targetedConfig.status." + (status.toLowerCase()))))+" ")]),(_vm.getCountItemsByStatus(status))?_c('v-badge',{staticStyle:{"z-index":"1"},attrs:{"content":_vm.getCountItemsByStatus(status),"color":"primary","offset-y":"-6","offset-x":"16","bordered":"","overlap":""}}):_vm._e()],1)}),0)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.configs,"items-per-page":25,"footer-props":{
                  itemsPerPageOptions: [25, 50, 100],
                },"search":_vm.search,"loading":_vm.isLoading,"atid":"table"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{staticClass:"ml-4",attrs:{"label":_vm.$lang('commonConfig.search'),"append-icon":"search","atid":"search_field","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":_vm.$lang('shared.showOnlyMy'),"hide-details":""},model:{value:(_vm.isShowOnlyMy),callback:function ($$v) {_vm.isShowOnlyMy=$$v},expression:"isShowOnlyMy"}})],1)],1)]},proxy:true},{key:"body",fn:function(props){return [(!props.items.length)?_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",style:([
                          {
                            color: _vm.$vuetify.theme.dark
                              ? 'rgba(255, 255, 255, 0.5)'
                              : 'rgba(0, 0, 0, 0.38)',
                          } ]),attrs:{"colspan":_vm.headers.length}},[_vm._v(" "+_vm._s(_vm.$lang("noDataText"))+" ")])])]):_c('draggable',{attrs:{"list":props.items,"ghost-class":"ghost","handle":".handle","tag":"tbody"},on:{"change":_vm.handleChangePriorities}},_vm._l((props.items),function(item,index){return _c('tr',{key:index,on:{"click":function($event){return _vm.handleClickByTableRow(item)}}},[_c('td',[_c('v-btn',{staticClass:"handle",staticStyle:{"cursor":"move"},attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-drag")])],1)],1),_c('td',[(index !== 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleMovingItemToTop(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-up")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("targetedConfig.moveToTop"))+" ")])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(_vm._s(item.activeSince))]),_c('td',[_vm._v(_vm._s(item.activeTill))]),_c('td',[_c('v-chip',{attrs:{"color":item.statusColor,"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$lang( ("targetedConfig.status." + (item.status.toLowerCase())) ))+" ")])],1),_c('td',[_vm._v(_vm._s(item.creatorName))]),_c('td',[_vm._v(" "+_vm._s(item.rules.length)+" "+_vm._s(_vm.$lang("commonConfig.rules"))+" ")])])}),0)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }