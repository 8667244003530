













































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";

import TestInfo from "@/ab-tests/components/TestInfo.vue";
import TargetedConfigStore from "@/ab-tests/store/TargetedConfigStore";
import TargetedConfigurationModel from "@/ab-tests/models/TargetedConfigurationModel";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import { AppSection, CurrentUserModel } from "@/shared/models";
import { ConfigType } from "@/ab-tests/models/ConfigResponseModel";

@Component({
  components: {
    draggable,
    TestInfo,
  },
})
export default class TargetedConfigurationView extends Vue {
  @Prop() value!: Array<ConfigsStatus>;

  private store!: TargetedConfigStore;
  search = "";
  isValidPriority = true;
  updatedTargetModel: TargetedConfigurationModel | null = null;
  isShowOnlyMy = false;

  readonly TARGETED_STATUSES = [
    ConfigsStatus.INIT,
    ConfigsStatus.SCHEDULED,
    ConfigsStatus.STARTED,
    ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
    ConfigsStatus.DISABLED,
  ];
  readonly headers = [
    {
      text: "",
      value: "draggable",
      width: "5%",
    },
    {
      text: "",
      value: "moveToTop",
      width: "5%",
    },
    {
      text: this.$lang("commonConfig.name"),
      align: "left",
      value: "name",
      width: "15%",
    },
    {
      text: this.$lang("commonConfig.activeSince"),
      value: "activeSince",
      width: "15%",
    },
    {
      text: this.$lang("commonConfig.activeTill"),
      value: "activeTill",
      width: "15%",
    },
    {
      text: this.$lang("commonConfig.statusTitle"),
      value: "status",
      width: "10%",
    },
    {
      text: this.$lang("commonConfig.creator"),
      value: "creatorName",
      width: "10%",
    },
    {
      text: this.$lang("commonConfig.rules"),
      value: "rules",
      width: "10%",
    },
  ];

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get checkedConfigStatuses(): Array<ConfigsStatus> {
    return this.value;
  }

  set checkedConfigStatuses(newValue: Array<ConfigsStatus>) {
    this.$emit("input", newValue);
  }

  get priorityFieldRules() {
    return [
      ValidUtil.requiredNumber(this.$lang("validation.required")),
      ValidUtil.integer(this.$lang("validation.integer")),
      ValidUtil.minNumber(1, this.$lang("validation.min", 1)),
      ValidUtil.max(
        this.configs.length,
        this.$lang("validation.max", this.configs.length)
      ),
    ];
  }

  get configs(): Array<TargetedConfigurationModel> {
    const configs = this.store.targetedConfigs.filter(
      (value) =>
        !this.checkedConfigStatuses.length ||
        this.checkedConfigStatuses.includes(value.status as ConfigsStatus)
    );

    return this.isShowOnlyMy
      ? configs.filter(
          (config: TargetedConfigurationModel) =>
            config.creatorId === this.currentUser.username
        )
      : configs;
  }

  get appId(): string {
    return this.$route.params.id;
  }

  get configStatusTotals(): Record<ConfigsStatus, number> {
    return this.$store.state.targetedConfig.configStatusTotals;
  }

  get isLoading(): boolean {
    return this.$store.state.targetedConfig.loadingConfig;
  }

  get hasCreatePermission(): boolean {
    return this.currentUser.createAccessEntities[this.appId].includes(
      AppSection.TARGETED_CONFIGS
    );
  }

  @Watch("checkedConfigStatuses")
  async watchCheckedConfigStatuses() {
    await this.$store.dispatch(
      "loadTargetedConfigsByStatuses",
      this.checkedConfigStatuses
    );
  }

  @Watch("appId", { immediate: true })
  async watchAppId() {
    await this.$store.dispatch(
      "loadTargetedConfigsByStatuses",
      this.checkedConfigStatuses
    );
    await this.$store.dispatch("loadTargetedConfigStatusTotals");
  }

  created() {
    this.store = this.$store.state.targetedConfig;
  }

  getCountItemsByStatus(status: ConfigsStatus): number {
    return this.configStatusTotals ? this.configStatusTotals[status] : 0;
  }

  handleClickByTableRow(item: TargetedConfigurationModel) {
    this.$router.push({
      name: "targeted_view",
      params: {
        configId: String(item.id),
      },
    });
  }

  async handleChangePriorities({
    moved,
  }: {
    moved: {
      element: TargetedConfigurationModel;
      newIndex: number;
      oldIndex: number;
    };
  }) {
    const found = this.configs[moved.newIndex];

    await this.$store.dispatch("updatePriorityConfig", {
      configType: ConfigType.TARGETED,
      payload: {
        id: moved.element.id,
        priority: Number(found?.priority),
      },
    });
    await this.$store.dispatch(
      "loadTargetedConfigsByStatuses",
      this.checkedConfigStatuses
    );
  }

  async handleMovingItemToTop(item: TargetedConfigurationModel) {
    await this.$store.dispatch("updatePriorityConfig", {
      configType: ConfigType.TARGETED,
      payload: {
        id: item.id,
        priority: 0,
      },
    });
    await this.$store.dispatch(
      "loadTargetedConfigsByStatuses",
      this.checkedConfigStatuses
    );
  }
}
